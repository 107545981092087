import { WIZARD_DOCS, backendClient, WIZARD_DOC_WIZARD_DATA, WIZARD_DOC_DELETE, WIZARD_DOC_ADD_CONV, WIZARD_DOC_CONVERSATIONS, WIZARD_DOC_APPROVE, WIZARD_DOC_ARCHIVE, HISTORY, GET_PRODUCTION_ORDER_DATE, IMPORT_CUSTOMER_DESIGN_FILES, CHANGE_ORDER_STATUS, DESIGN_LINKS, GET_DESIGN_FEES, DESIGN_WEI_REJECT, GET_SEW_DISK_FEES } from "../config";
import { HistoryDesign, SelectAllQueryResult, DesignDoc, WizardDocConversation, WizardDocConversationWithOrderN, WizardDocOneTimeFee, SewDiskFee } from "../types/api-types"
import { IProdOrderLineItem } from "../types/iprod-order"



// export const generateSanaDoc = async () => {

//   const params = {
//     resource: GENERATE_SANA_DOC,
//     payload: {}
//   };
//   return backendClient.post(params);
// }

// export const createWizardDoc = async (payload: WizardDoc) => {
//   const params = {
//     resource: WIZARD_DOCS,
//     payload: payload
//   };
//   return backendClient.post(params);
// }

export const patchWizardDoc = async (id: string, payload: Partial<DesignDoc>) => {
  try {
    const params = {
      resource: WIZARD_DOCS,
      payload: { id, ...payload }
    };
    return await backendClient.patch(params);
  } catch (error) {
    console.error('Error in patching wizard doc:', error);
    throw error;
  }
}

export const patchWizardDocWizardData = async (id: string, wizardData: any) => {
  try {
    const params = {
      resource: WIZARD_DOC_WIZARD_DATA,
      payload: { designId: id, wizardData }
    };
    return await backendClient.patch(params);
  } catch (error) {
    console.error('Error in patching wizard doc:', error);
    throw error;
  }
}

export const listWizardDocs = async (pageNumber?: number, pageSize?: number, statuses?: string[], searchText?: string, customerId?: string, archived?: boolean): Promise<SelectAllQueryResult<DesignDoc>> => {
  try {
    const payload = { pageNumber, pageSize, additional: { statuses, searchText, customerID: customerId, archived } };
    const params = { resource: WIZARD_DOCS, payload: payload };

    return await backendClient.get(params);
  } catch (error) {
    console.error('Error in listWizardDocs request:', error);
    throw error;
  }
}

export const getWizardDocById = async (id: string): Promise<DesignDoc> => {
  const params = {
    resource: `${WIZARD_DOCS}/${id}`
  };
  const wizardDoc = await backendClient.get<undefined, DesignDoc>(params);

  const safeParseJSON = (jsonString: string) => {
    try {
      const parsed = JSON.parse(jsonString);
      return parsed;
    } catch (e) {
      return jsonString; // return original string if parsing fails
    }
  };

  //HACK, why doesn't server return JSON?
  if (wizardDoc.productionArtwork) {
    wizardDoc.productionArtwork = safeParseJSON(wizardDoc.productionArtwork as unknown as string);
  }
  if (wizardDoc.productionArtworkPreview) {
    wizardDoc.productionArtworkPreview = safeParseJSON(wizardDoc.productionArtworkPreview as unknown as string);
  }

  return wizardDoc;
};

// export const listWizardDocStatus = async (): Promise<{ id: string, code: string }[]> => {
//   const params = {
//     resource: WIZARD_DOC_STATUS_LIST
//   }
//   const res = await backendClient.get(params) as { data: { id: string, code: string, description: string }[] }
//   const data = res.data
//   return data.map((status) => {
//     return {
//       id: status.id,
//       code: status.code,
//       description: status.description
//     }
//   })
// }

export const addItemConversation = async (conversation: WizardDocConversationWithOrderN): Promise<DesignDoc[]> => {
  const params = {
    resource: WIZARD_DOC_ADD_CONV,
    payload: conversation
  };
  return backendClient.post(params);
}

// export const itemChangeStatus = async (itemId: number, newStatusId: string): Promise<WizardDoc[]> => {
//   const params = {
//     resource: WIZARD_DOC_CHANGE_STATUS,
//     payload: { itemId, newStatusId }
//   };
//   return backendClient.post(params);
// }

export const listWizardDocConversations = async (id: string): Promise<WizardDocConversation[]> => {
  const params = {
    resource: `${WIZARD_DOC_CONVERSATIONS}/${id}`
  };
  return backendClient.get(params);
}

// export const wizardDocAccept = async (designId: number,
//   orderN: string,
//   lineN: string): Promise<WizardDoc[]> => {
//   const params = {
//     resource: WIZARD_DOC_ACCEPT,
//     payload: { designId, orderN, lineN }
//   };
//   return backendClient.post(params);
// }

// export const wizardDocRelease = async (designId: number): Promise<WizardDoc[]> => {
//   const params= {
//     resource: WIZARD_DOC_RELEASE,
//     payload: { designId }
//   };
//   return backendClient.post(params);
// }

export const wizardDocApprove = async (designId: string): Promise<boolean> => {
  const params = {
    resource: WIZARD_DOC_APPROVE,
    payload: { designId }
  };
  return backendClient.post(params);
}

export const wizardDocArchive = async (designId: string): Promise<boolean> => {
  const params = {
    resource: WIZARD_DOC_ARCHIVE,
    payload: { itemId: designId }
  };
  return backendClient.post(params);
}

export const wizardDocDelete = async (designId: string): Promise<boolean> => {
  const params = {
    resource: WIZARD_DOC_DELETE,
    payload: { itemId: designId }
  };
  return backendClient.post(params);
}


// export const wizardDocCancel = async (designId: number): Promise<WizardDoc[]> => {
//   const params = {
//     resource: WIZARD_DOC_CANCEL,
//     payload: { designId }
//   };
//   return backendClient.post(params);
// }


export const listHistoryDocs = async (pageNumber?: number, pageSize?: number, customerId?: string, search?: string): Promise<SelectAllQueryResult<HistoryDesign>> => {
  try {
    const params = {
      resource: HISTORY,
      payload: {
        page: pageNumber,
        pageSize,
        customer: customerId,
        search
      }
    };

    const resp = await backendClient.post(params) as SelectAllQueryResult<HistoryDesign>;
    return {
      data: resp.data,
      totalFieldsAffected: resp.totalFieldsAffected
    };
  } catch (error) {
    console.error('Error occurred while fetching history documents:', error);
    throw error;
  }
};


// export const getHistoryDocById = async (id: number): Promise<WizardDoc> => {
//   const params = {
//     resource: `${HISTORY}/${id}`
//   };
//   return backendClient.get(params);
// }

export const getProductionOrderData = async (orderN: string): Promise<IProdOrderLineItem[]> => {
  try {
    const params = {
      resource: `${GET_PRODUCTION_ORDER_DATE}/${orderN}`
    };

    return backendClient.get(params);

  } catch (error) {
    console.error('getProductionOrderData: ', error);
    throw error;
  }
}

// export const listCustomerDesignFiles = async (designId: string, customerId: string) => {
//   const params = {
//     resource: CUSTOMER_DESIGN_FILES,
//     payload: {
//       customer: customerId,
//       designId
//     }
//   };
//   const resp = await backendClient.post(params) as SelectAllQueryResult<WizardDoc>
//   return {
//     data: resp.data,
//     totalFieldsAffected: resp.totalFieldsAffected
//   }
// }

export const importCustomerDesignFiles = async (customerId: string, design: HistoryDesign) => {
  const params = {
    resource: IMPORT_CUSTOMER_DESIGN_FILES,
    payload: {
      customer: customerId,
      design
    }
  };
  try {
    const response = await backendClient.post(params);
    return response as string;
  } catch (error) {
    console.error('importCustomerDesignFiles: ', error);
    throw error;
  }
}

export const changeOrderStatus = async (
  designId: string,
  newStatusId: string
): Promise<boolean> => {
  const params = {
    resource: CHANGE_ORDER_STATUS,
    payload: { designId, newStatusId }
  };
  return backendClient.post(params);
}

export const linkDocument = async (designId: string, orderN: string, lineN: number) => {
  const params = {
    resource: DESIGN_LINKS,
    payload: { designId, orderN, lineN }
  };
  return backendClient.post<typeof params['payload'], typeof params['payload']>(params)
}

export const getDesignFees = async (documentId: string): Promise<WizardDocOneTimeFee[]> => {
  try {
    const params = {
      resource: `${GET_DESIGN_FEES}/${documentId}`
    };

    return await backendClient.get(params);
  } catch (error) {
    console.error(`Failed to fetch design Art Charge: ${error}`);
    throw error;
  }
};

export const getQuote = async (wizardData:any): Promise<any> => {
  try {
    const resp = await fetch('https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/13/artworkupload/buildquote', {
      method: 'POST',
      body: JSON.stringify(wizardData)
    })
    return await resp.json()
  } catch (error) {
    console.error(`Failed to fetch quote: ${error}`);
    return undefined
  }
}

export const rejectProof = async (id: string, adminId: string) => {
  const params = {
    resource: DESIGN_WEI_REJECT,
    payload: { id, adminId }
  };

  return backendClient.post<typeof params['payload'], typeof params['payload']>(params);
};

export const getSewDiskFees = async (documentId: string): Promise<SewDiskFee[]> => {
  try {
    const params = {
      resource: `${GET_SEW_DISK_FEES}/${documentId}`
    };

    return await backendClient.get(params);
  } catch (error) {
    console.error(`Failed to fetch Sew Disk Fees: ${error}`);
    throw error;
  }
};