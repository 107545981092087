import { useEffect, useState } from 'react';
import Spinner from './spinner';
import CommentBox from '../../../components/commentBox/commentBox';
import { addAuthorizationHeader } from '../../../api/network';
import { DesignDoc, SewDiskFee } from '../../../types/api-types';
import { InternalArtStates } from '../../../types/art-status';
import { getSewDiskFees } from '../../../api/backend';
import { getBackendApiUrl } from '../../../config';

// Styles
import styles from './sewDiskFeesModal.module.css';

// Defining the prop types
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  document?: DesignDoc;
  addSewDiskFeesSuccess: (comment: string) => void;
  isCustomerView: boolean;
}

const SewDiskFeesModal: React.FC<ModalProps> = ({ isOpen, onClose, document, addSewDiskFeesSuccess, isCustomerView }) => {
  const documentId = document?.id;
  const isApproved = document?.statusID == InternalArtStates.APPROVED;
  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [hours, setHours] = useState<number>(0);
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      setError(null);
      handleFetchFees();
    } else {
      setHours(0);
      setComment('');
    }
  }, [isOpen]);

  // GET 
  const handleFetchFees = async () => {
    if (documentId) {
      setProcessing(true);
      try {
        const fees: SewDiskFee[] = await getSewDiskFees(documentId);
        if (fees && fees?.length > 0)
          setHours(fees[0]?.quantity);

      } catch (err) {
        setError("Failed to fetch Sew Disk Fee. Please try again.");
      } finally {
        setProcessing(false);
      }
    }
  };

  // PUT
  const saveSewDiskFee = async () => {
    try {
      setProcessing(true);
      setError(null);

      const headers = addAuthorizationHeader({
        'Content-Type': 'application/json',
      });

      const response = await fetch(`${getBackendApiUrl()}/design-sew-file-hours/${documentId}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ hours }),
      });

      if (!response.ok) {
        throw new Error("Error updating Sew Disk Fee: " + await response.text());
      }

      const updatedDoc = await response.json();
      console.log("updated doc: ", updatedDoc);

      if (addSewDiskFeesSuccess) {
        addSewDiskFeesSuccess(comment);
        setComment('');
      }

      setProcessing(false);
      onClose();
    } catch (err) {
      setError("Failed to set Sew Disk Fees: " + (err as Error).message);
      setProcessing(false);
    }
  };

  const handleInputChange = (value: string) => {
    const hs = parseInt(value, 10);
    if (!isNaN(hs) && hs >= 0) {
      setHours(hs);
    }
  };

  const handleCommentChange = (value: string) => {
    setComment(value);
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (hours >= 0) {
      saveSewDiskFee();
    } else {
      setError("Hours must be a non-negative number.");
    }
  };

  function CloseIcon() {
    return (
      <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span className="sr-only">Close menu</span>
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    );
  }

  return isOpen ? (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          <div className={styles.header}>
            <span>Sew Disk Hours</span>
            <div className={styles.closeIcon} onClick={() => { onClose(); }}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.modalContent}>
            {error && <div className={styles.errorMessage}>{error}</div>}
            <form onSubmit={handleSubmit}>
              <div className={styles.inputsContainer}>
                <div className={styles.inputWrapper}>
                  <label htmlFor="hours">Hours:</label>
                  <input
                    id="hours"
                    type="number"
                    value={hours}
                    onChange={(e) => handleInputChange(e.target.value)}
                    required
                  />
                </div>
              </div>

              {/* Comment Section */}
              {!isApproved &&
                <CommentBox
                  onCommentChange={handleCommentChange}
                  isCustomerView={isCustomerView}
                  showTitle={true} />
              }
              <div className={styles.confirmBtns}>
                <button className={[styles.button, styles.cancelBtn].join(' ')} onClick={() => { onClose(); }} disabled={processing}>Cancel</button>
                <button
                  type="submit"
                  className={[styles.addButton, (processing) ? styles.disabledBtn : ''].join(' ')}
                  disabled={processing}>
                  {processing ? <Spinner spinning={processing} color="#F36E29" size={{ width: "22px", height: "22px" }} /> : 'Save'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default SewDiskFeesModal;