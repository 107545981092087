import React, { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import appModel from '../../../models/app-model'
import { addToCart, redirectToLNTransfer } from '../../../utils/cart'
import Spinner from './spinner'
import { approveDesign, updateDesignDetails } from '../../../models/app-actions'
import { getBackendApiUrl } from "../../../config"
import { addAuthorizationHeader } from '../../../api/network'
import { getDesignFees } from '../../../api/backend'
import { AxiosError } from 'axios'
import { FlexStyleProductTypes, WEIPVCProductTypes } from '../../../types/product-types'

// Styles
import styles from './customerWorkflow.module.css'

interface CustomerWorkFlowProps {
  itemId: string;
  docType: string;
  wizardType: string;
  wasPurchased: boolean;
}

const CustomerWorkFlow: React.FC<CustomerWorkFlowProps> = ({ itemId, docType, wizardType, wasPurchased }) => {
  const appSnap = useSnapshot(appModel);
  const wizardDoc = appSnap.activeDesignDoc;
  const docId: string = wizardDoc?.id as string;
  const statusID: string = wizardDoc?.statusID as string;
  const customerId = appSnap.customerId || "";
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [unpaidArtFees, setUnpaidArtFees] = useState<boolean>(false);
  const isWizardDesign = docType === "WizardDesign";
  const isCustomProduct = wizardType === "CustomProduct";
  const isLNProof = wizardDoc?.isLNProof ?? false;
  const canEditAndOrder = !isLNProof && wizardDoc?.docType === "LNTransfer";
  const hasProof = !!wizardDoc?.productionArtworkPreview?.length
  const isFlexStyle = FlexStyleProductTypes.includes(wizardDoc?.wizardID ?? "");
  const isWEIPVC = appSnap.activeDesignDoc?.docType == "WizardDesign" && WEIPVCProductTypes.includes(appSnap.activeDesignDoc.wizardID) && false;

  useEffect(() => {
    handleFetchFees();
  }, [wizardDoc?.id]);

  // Get Design Fees 
  const handleFetchFees = async () => {
    if (wizardDoc?.id) {
      setIsLoading(true);
      setProcessing(true);
      try {
        const fees = await getDesignFees(wizardDoc.id);
        const hasUnpaidArtFees = fees?.some(fee => fee.feeType === "ArtFee" && !fee.paid);
        setUnpaidArtFees(hasUnpaidArtFees);
      } catch (err) {
        setError("Failed to fetch Art Charges. Please try again.");
      } finally {
        setProcessing(false);
        setIsLoading(false);
      }
    }
  };

  const handleAddToCart = async (itemId: string, docType: string, wizardType: string, isLNProof: boolean, statusID: string) => {
    try {
      setIsLoading(true);
      addToCart(itemId, customerId, docType, wizardType, isLNProof, statusID);
    } catch (err: any) {
      if(err && err.name && err.name==="AxiosError") {
        const errorData = (err as AxiosError)?.response?.data
        const error = errorData == null ? null : typeof errorData == "object" && "error" in errorData && typeof errorData.error == "string" ? errorData.error : JSON.stringify(errorData)
        setError(error);
      }
      else {
        console.log(err)
        setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditAndOrder = async (itemId: string, wizardType: string) => {
    try {
      setIsLoading(true);
      await redirectToLNTransfer(itemId, customerId, wizardType, false, false);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }

  const updateApprovedState = async (itemId: string) => {
    try {
      setIsLoading(true);
      const updateItemState = await approveDesign(itemId)
      await updateDesignDetails();
      console.log(updateItemState);

    } catch (err: any) {
      if(err && err.name && err.name==="AxiosError") {
        const errorData = (err as AxiosError)?.response?.data
        const error = errorData == null ? null : typeof errorData == "object" && "error" in errorData && typeof errorData.error == "string" ? errorData.error : JSON.stringify(errorData)
        setError(error);
      }
      else {
        console.log(err)
        setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const canApprove = appSnap.activeDesignDoc?.statusID == "PEND_C" && !!appSnap.activeDesignDoc?.productionArtworkPreview?.length;
  const canAddToCart = appSnap.activeDesignDoc?.statusID == "APPROVED" || appSnap.activeDesignDoc?.statusID == "REVIEW";
  const isReview = appSnap.activeDesignDoc?.statusID == "REVIEW"
  const buttonClasses = `${styles.orangeBtn} flex flex-row gap-1 text-white hover:bg-blue-500`;

  const orderFlexStyleSample = (docId: string) => {
    fetch(`${getBackendApiUrl()}/DesignSamples`, {
      method: 'POST',
      headers: addAuthorizationHeader({
        "content-type": "application/json",
      }),
      body: JSON.stringify({
        id: docId
      })
    }).then(async (samplesResp) => {
      if (!samplesResp.ok) throw new Error("Error getting purchase data " + await samplesResp.text())
      const samplesRespJson = await samplesResp.json()
      const itemGroup = { items: [{ productId: samplesRespJson.itemNo, uomId: samplesRespJson.UofM, ...samplesRespJson }] }

      parent.postMessage(itemGroup, '*');
      console.log('parent.postMessge', JSON.stringify(itemGroup, null, 2))

    }).catch(err => {
      throw err
    })
  }

  const orderWeiPvcSample = (docId: string) => {
    fetch(`${getBackendApiUrl()}/DesignSamples`, {
      method: 'POST',
      headers: addAuthorizationHeader({
        "content-type": "application/json",
      }),
      body: JSON.stringify({
        id: docId
      })
    }).then(async (samplesResp) => {
      if (!samplesResp.ok) throw new Error("Error getting purchase data " + await samplesResp.text())
      const samplesRespJson = await samplesResp.json()
      const itemGroup = { items: [{ productId: samplesRespJson.itemNo, uomId: samplesRespJson.UofM, ...samplesRespJson }] }

      parent.postMessage(itemGroup, '*');
      console.log('parent.postMessge', JSON.stringify(itemGroup, null, 2))

    }).catch(err => {
      throw err
    })
  }

  const payArtFees = async (docId: string) => {
    setProcessing(true);
    setIsLoading(true);
    setError(null);

    fetch(`${getBackendApiUrl()}/DesignPayArtFees`, {
      method: 'POST',
      headers: addAuthorizationHeader({
        "content-type": "application/json",
      }),
      body: JSON.stringify({
        id: docId
      })
    }).then(async (samplesResp) => {
      if (!samplesResp.ok) throw new Error("Failed to pay Art Fees. " + await samplesResp.text())
      const samplesRespJson = await samplesResp.json()
      const itemGroup = { items: [{ productId: samplesRespJson.itemNo, uomId: samplesRespJson.UofM, ...samplesRespJson }] }

      parent.postMessage(itemGroup, '*');
      console.log('parent.postMessge', JSON.stringify(itemGroup, null, 2))
      updateDesignDetails();

    }).catch(err => {
      setError('Failed to pay Art Fees. Please try again.');
      throw err;
    }).finally(() => {
      setProcessing(false);
      setIsLoading(false);
    })
  };

  return (
    <>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.customerWrapper}>
        <Spinner spinning={isLoading} />
        {!isLoading && !processing && (
          <>
            {unpaidArtFees ? (
              <button
                type="button"
                className={buttonClasses}
                onClick={() => payArtFees(docId)}
              >
                Pay Art Fees
              </button>
            ) : (
              <>
                {(!isReview && canApprove && !(isCustomProduct && isWizardDesign)) && (
                  <button
                    type="button"
                    className={buttonClasses}
                    onClick={() => updateApprovedState(itemId)}
                  >
                    I Approve Artwork
                  </button>
                )}
                {canEditAndOrder && (
                  <button
                    type="button"
                    className={buttonClasses}
                    onClick={() => handleEditAndOrder(itemId, wizardType)}
                  >
                    Edit & Order
                  </button>
                )}
                {canAddToCart && (
                  <button
                    type="button"
                    className={buttonClasses}
                    onClick={() => handleAddToCart(itemId, docType, wizardType, isLNProof, statusID)}
                  >
                    Add To Cart
                  </button>
                )}
                {isFlexStyle && !wasPurchased && hasProof && (
                  <button
                    type="button"
                    className={buttonClasses}
                    onClick={() => orderFlexStyleSample(docId)}
                  >
                    Order Sample
                  </button>
                )}
                {isWEIPVC && !wasPurchased && hasProof && (
                  <button
                    type="button"
                    className={buttonClasses}
                    onClick={() => orderWeiPvcSample(docId)}
                  >
                    Order Sample
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CustomerWorkFlow;