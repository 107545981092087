import { HttpClient } from "./api/network";

enum Envrio {
  LOCAL,
  DEV,
  PROD
}

function getEnviro(): Envrio {
  const hostname = window.location.hostname
  if (hostname.includes('localhost')) {
    return Envrio.LOCAL
  } else if (hostname.includes('dev') || hostname.includes('edge')) {
    return Envrio.DEV
  } else {
    return Envrio.PROD
  }
}

function getCaseInsensitiveParam(params: URLSearchParams, paramName: string) {
  paramName = paramName.toLowerCase();
  for (const [key, value] of params.entries()) {
    if (key.toLowerCase() === paramName) {
      return value;
    }
  }
  return null;
}

export function isInternal() {
  const params = new URLSearchParams(window.location.search)
  const internal = getCaseInsensitiveParam(params, 'internal')?.toLowerCase() == "true"
  return internal
}

function getUrls() {
  const params = new URLSearchParams(window.location.search)
  const apiUrl = getCaseInsensitiveParam(params, 'apiurl')

  const enviro = getEnviro()
  switch (enviro) {
    case Envrio.LOCAL:
      return {
        API_URL : apiUrl ?? 'http://localhost:5000/api/v1',
        DWS_URL : 'dws.sca.poweredbycadworx.com',
        LN_URL : 'cws.sca.poweredbycadworx.com',
        CORE_API_URL: 'https://stahls-canada.poweredbycadworx.com'
      }
    
      break
    case Envrio.DEV:
      return {
        API_URL : apiUrl ?? 'https://art-api-dev.sca.poweredbycadworx.com/api/v1',
        DWS_URL : 'dws-dev.sca.poweredbycadworx.com',
        LN_URL : 'cws-dev.sca.poweredbycadworx.com',
        CORE_API_URL: 'https://stahls-canada.poweredbycadworx.com'
      }
    default:
      return {
        API_URL : apiUrl ?? 'https://art-api.sca.poweredbycadworx.com/api/v1',
        DWS_URL : 'dws.sca.poweredbycadworx.com',
        LN_URL : 'cws.sca.poweredbycadworx.com',
        CORE_API_URL: 'https://stahls-canada.poweredbycadworx.com'
      }
  }

}

export function getBackendAPIDomain() {
  const urls = getUrls();
  const fullUrl = urls.API_URL;

  try {
    const { protocol, host } = new URL(fullUrl);
    return `${protocol}//${host}`;
  } catch (error) {
    console.error("Invalid URL:", fullUrl);
    return null;
  }
}

export function getCoreAPIUrl(){
  const urls = getUrls()
  return urls.CORE_API_URL
}

export const getBackendApiUrl = () => {
  const urls = getUrls()
  return urls.API_URL
}

export const getDWSUrl = () => {
  const urls = getUrls()
  return urls.DWS_URL
}

export const getLNUrl = () => {
  const urls = getUrls()
  return urls.LN_URL
}


export const ITEMS_PER_PAGE = 12;
export const DEFAULT_IMAGE = 'no-image-icon.png';

const BACKEND_URL = getBackendApiUrl();
export const backendClient = new HttpClient(BACKEND_URL);

export const GENERATE_SANA_DOC = 'Designs';
export const GET_WIZARD_DOC_BY_ID = 'Designs';
export const WIZARD_DOCS = 'Designs';
export const WIZARD_DOC_CONVERSATIONS = 'DesignComments';
export const WIZARD_DOC_STATUS_LIST = 'DesignStatuses';
export const WIZARD_DOC_UPDATE = 'Designs';
export const WIZARD_DOC_ADD_CONV = 'DesignComments';
//export const WIZARD_DOC_CHANGE_STATUS = 'DesignUpdateStatus';
//export const WIZARD_DOC_ACCEPT = 'AcceptProductionArt';
export const WIZARD_DOC_APPROVE = 'design-approvals';
export const WIZARD_DOC_ARCHIVE = 'design-archive';
export const WIZARD_DOC_DELETE = 'design-delete';
export const WIZARD_DOC_WIZARD_DATA = 'design-wizard-data';
//export const WIZARD_DOC_RELEASE = 'ReleaseProductionArt';
//export const WIZARD_DOC_CANCEL = 'CancelProductionArt';
export const HISTORY = 'history';
export const GET_PRODUCTION_ORDER_DATE = 'GetProductionOrderData';
export const CUSTOMER_DESIGN_FILES = 'listcustomerdesignfiles';
export const IMPORT_CUSTOMER_DESIGN_FILES = 'importcustomerdesign';
export const CHANGE_ORDER_STATUS = 'design-status';
//export const COMPLETE_PRODUCTION_ART = 'CompleteProductionArt';
export const DESIGN_LINKS = 'desgin-links';
export const GET_DESIGN_FEES = 'DesignOneTimeFee';
export const DESIGN_WEI_REJECT = 'DesignWEIReject';
export const GET_SEW_DISK_FEES = 'design-sew-file-hours';
// export const API_DOCS = `${SERVER}/api/v1/wizardDocs`
// export const API_UPLOAD = `${SERVER}/api/v1/upload`

